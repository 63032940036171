export default {
  // "::-webkit-scrollbar": {
  //   width: "8px",
  //   height: "8px"
  // },
  // "::-webkit-scrollbar-thumb": {
  //   borderRadius: "4px",
  //   backgroundColor: "rgba(255, 255, 255, 0.5)",
  //   visibility: "hidden",
  //   "&:hover": {
  //     backgroundColor: "rgba(255, 255, 255, 0.6)"
  //   }
  // },
  // "::-webkit-scrollbar-track-piece": {
  //   borderRadius: "4px",
  //   backgroundColor: "transparent"
  // },
  // "::-webkit-scrollbar-corner": {
  //   backgroundColor: "transparent"
  // },
  // "*:hover": {
  //   "::-webkit-scrollbar-thumb": {
  //     visibility: "visible"
  //   }
  // }
} as any;
