import React from "react";
import { Routes, Route } from "react-router-dom";
import App, { Loading } from "./pages/_app";
import NotFound from "./pages/404";
const Home = React.lazy(() => import("./pages/index"));

export default function RoutesConfig() {
  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
}
