import * as React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { HashRouter } from "react-router-dom";
import RoutesConfig from "./routes";
import GlobalStyles from "@mui/material/GlobalStyles";
import styles from "./globalstyles";
import { light } from "./theme";

async function startApp() {
  const rootElement = document.getElementById("root");
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={light}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        {/* 全局通用样式 */}
        <GlobalStyles styles={styles} />
        <HashRouter>
          <RoutesConfig />
        </HashRouter>
      </ThemeProvider>
    </React.StrictMode>,
    rootElement
  );
}

startApp();
