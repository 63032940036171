import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export default function NotFound() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        userSelect: "none"
      }}
    >
      <Typography sx={{ fontSize: "20px", mt: 1 }}>找不到此页面</Typography>
      <Button href="/" variant="text" sx={{ mt: 1 }}>
        回到首页
      </Button>
    </Box>
  );
}
