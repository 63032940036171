import { createTheme } from "@mui/material/styles";
import { LinkBehavior } from "../components/Link";

const theme = createTheme({
  colorScheme: "dark",
  breakpoints: {
    values: {
      xs: 0,
      sm: 544,
      md: 768,
      lg: 1024,
      xl: 1280
    }
  },
  palette: {
    mode: "dark",
    background: {
      default: "#000",
      panel: "#1d1d1f"
    },
    primary: {
      main: "#037aff"
    },
    text: {
      primary: "#F5F5F5"
    },
    border: {
      primary: "#1d1d1d"
    },
    link: {
      primary: "#2997FF"
    },
    affect: {
      positive: "#eb0f29",
      negative: "#00873c",
      neutral: "#5b636a",
      inactive: "#b9b8c5"
    }
  },
  typography: {
    fontFamily: `"Roboto","Helvetica Neue","Helvetica","Arial",sans-serif`,
    htmlFontSize: 16,
    fontSize: 14,
    h1: { fontSize: 20 },
    h2: { fontSize: 18 },
    h3: { fontSize: 16 },
    h4: { fontSize: 14 },
    h5: { fontSize: 13 },
    h6: { fontSize: 12 },
    button: { fontSize: 12 },
    caption: { fontSize: 12 },
    body1: { fontSize: 12 }
    // body2: { fontSize: 12 }
  },
  components: {
    MuiLink: {
      defaultProps: {
        // @ts-ignore
        component: LinkBehavior
      }
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    }
  }
});

export default theme;
