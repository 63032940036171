import React from "react";
import { Outlet } from "react-router-dom";

export default function App() {
  const [pass, setPass] = React.useState(false);

  React.useEffect(() => {
    setPass(true);
  }, []);

  return pass ? <Outlet /> : null;
};

export function Loading() {
  // console.log("loading page");
  return <></>;
}
